import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import 'bootstrap/dist/css/bootstrap.min.css';
import { headData } from '../mock/data';
import '../style/main.scss';

export default () => {
  const { lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Riot TXT</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="Riot TXT Code" />
      </Helmet>
      <a href="/Riot.txt" download>
        Click to download
      </a>
    </>
  );
};
